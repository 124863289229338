const EXERCISE_SETS_KEY = "exerciseSets";
const EXERCISE_LOG_KEY = "exerciseLog";

/**
 * Отримує всі дані з localStorage або повертає порожній об'єкт.
 * @param {string} key Ключ для отримання даних з localStorage.
 * @returns {Object} Дані з localStorage.
 */
function getDataFromLocalStorage(key) {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : {};
}

/**
 * Зберігає дані в localStorage.
 * @param {string} key Ключ для збереження.
 * @param {Object} data Дані для збереження.
 */
function saveDataToLocalStorage(key, data) {
    localStorage.setItem(key, JSON.stringify(data));
}

/**
 * Отримує всі дані з localStorage для вправ.
 * @returns {Object} Всі дані вправ.
 */
export function getAllExerciseSets() {
    return getDataFromLocalStorage(EXERCISE_SETS_KEY);
}

/**
 * Зберігає дані про вправи в localStorage.
 * @param {Object} exerciseSets Дані вправ для збереження.
 */
export function saveAllExerciseSets(exerciseSets) {
    saveDataToLocalStorage(EXERCISE_SETS_KEY, exerciseSets);
}

/**
 * Отримує підходи для конкретної вправи.
 * @param {string} exerciseId ID вправи.
 * @returns {Array} Список підходів для вправи.
 */
export function getExerciseSets(exerciseId) {
    const allExercisesSets = getAllExerciseSets();
    return allExercisesSets[exerciseId] || [];
}

/**
 * Зберігає підходи для конкретної вправи.
 * @param {string} exerciseId ID вправи.
 * @param {Array} sets Список підходів для збереження.
 */
export function saveExerciseSets(exerciseId, sets) {
    const allExercisesSets = getAllExerciseSets();
    allExercisesSets[exerciseId] = sets;
    saveAllExerciseSets(allExercisesSets);
}

/**
 * Отримує всі дані про вправи за датами з localStorage.
 * @returns {Object} Дані про вправи, згруповані за датами.
 */
export function getExerciseLog() {
    return getDataFromLocalStorage(EXERCISE_LOG_KEY);
}

/**
 * Зберігає дані про вправи за датами в localStorage.
 * @param {Object} log Дані для збереження.
 */
export function saveExerciseLog(log) {
    saveDataToLocalStorage(EXERCISE_LOG_KEY, log);
}

/**
 * Отримує дані про вправи для конкретної дати.
 * @param {string} date Дата у форматі "YYYY-MM-DD".
 * @returns {Object} Дані про вправи для конкретної дати.
 */
export function getExerciseLogByDate(date) {
    const log = getExerciseLog();
    return log[date] || {};
}

/**
 * Зберігає дані про вправи для конкретної дати.
 * @param {string} date Дата у форматі "YYYY-MM-DD".
 * @param {Object} data Дані для збереження.
 */
export function saveExerciseLogByDate(date, data) {
    const log = getExerciseLog();
    log[date] = data;
    saveExerciseLog(log);
}

import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { getAllExercises, getExercisesByMuscleGroup, getMuscleGroupById } from "../services/exerciseService";
import "./SelectExercise.css";

export default function SelectExercise() {
    const { muscleGroup } = useParams(); // Get muscle group from URL
    const navigate = useNavigate(); // Navigation hook
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        if (searchTerm.length > 0 && muscleGroup) {
            // Redirect to show all exercises when searching
            navigate("/SelectExercise");
        }
    }, [searchTerm, muscleGroup, navigate]);

    let muscleGroupName = "All Exercises";
    let directExercises = [];
    let indirectExercises = [];
    let allExercises = getAllExercises();

    if (muscleGroup) {
        // Get exercises for a specific muscle group
        const muscleGroupObj = getMuscleGroupById(muscleGroup);
        directExercises = getExercisesByMuscleGroup(muscleGroup, "primary");
        indirectExercises = getExercisesByMuscleGroup(muscleGroup, "secondary");
        muscleGroupName = muscleGroupObj ? muscleGroupObj.name : muscleGroup;
    }

    // If search is active, filter all exercises
    let filteredExercises = allExercises;
    let filteredDirectExercises = directExercises;
    let filteredIndirectExercises = indirectExercises;

    if (searchTerm.length > 0) {
        filteredExercises = allExercises.filter((exercise) =>
            exercise.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        filteredDirectExercises = [];
        filteredIndirectExercises = [];
    }

    // Render exercise list with category
    const renderExerciseList = (exercises, title) => (
        <div className="exercise-category">
            <h2>{title}</h2>
            {exercises.length === 0 ? (
                <p>No {title.toLowerCase()} found.</p>
            ) : (
                <ul>
                    {exercises.map((exercise) => (
                        <li key={exercise.id}>
                            <Link to={`/LogExercise/${exercise.id}`} className="exerciseLink">
                                {exercise.name}
                            </Link>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );

    return (
        <div className="container">
            <h1>Exercises for: {muscleGroup ? muscleGroupName : "All Exercises"}</h1>
            <Link to="/" className="backLink">← Back to Muscle Group List</Link>

            {/* Search Bar */}
            <input
                type="text"
                className="searchInput"
                placeholder="Search exercises..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />

            {/* Exercise Categories */}
            {muscleGroup ? (
                <>
                    {renderExerciseList(filteredDirectExercises, "Direct Exercises")}
                    {renderExerciseList(filteredIndirectExercises, "Indirect Exercises")}
                </>
            ) : (
                // If searching or no muscle group is selected, show all exercises
                renderExerciseList(filteredExercises, "All Exercises")
            )}
        </div>
    );
}

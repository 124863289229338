// src/data/muscleGroups.js
export const muscleGroups = [
    { id: "chest", name: "Chest" },
    { id: "back", name: "Back" },
    { id: "legs", name: "Legs" },
    { id: "deltoids", name: "Deltoids" },
    { id: "biceps", name: "Biceps" },
    { id: "triceps", name: "Triceps" },
    { id: "abs", name: "Abs" },
    { id: "forearms", name: "Forearms" },
    { id: "glutes", name: "Glutes" },
    { id: "hamstrings", name: "Hamstrings" },
    { id: "quadriceps", name: "Quadriceps" },
    { id: "calves", name: "Calves" },
    { id: "trapezius", name: "Trapezius" },
    { id: "rear_deltoids", name: "Rear Deltoids" },
    { id: "upper_back", name: "Upper Back" },
    { id: "lower_back", name: "Lower Back" },
    { id: "hip_flexors", name: "Hip Flexors" },
    { id: "obliques", name: "Obliques" },
];

import { exercises } from "../data/exercises";
import { muscleGroups } from "../data/muscleGroups";
import { getExerciseLog } from "../utils/localStorageUtils";

/**
 * Повертає останні N дат у форматі YYYY-MM-DD
 * @param {number} n Кількість останніх дат.
 * @returns {Array<string>} Масив останніх n дат.
 */
export function getLastNDates(n) {
    const dates = [];
    const today = new Date();

    for (let i = 0; i < n; i++) {
        const date = new Date(today);
        date.setDate(today.getDate() - i);
        dates.push(date.toISOString().split("T")[0]); // Формат YYYY-MM-DD
    }

    return dates;
}

/**
 * Підраховує кількість підходів для м'язових груп за кожну дату.
 * @param {Array<string>} dates Масив дат.
 * @returns {Object} Дані про кількість підходів для кожної м'язової групи за кожну дату.
 */
export function calculateMuscleGroupCountsByDate(dates) {
    const log = getExerciseLog();
    const counts = {};

    // Створюємо словник вправ для швидкого доступу
    const exerciseMap = exercises.reduce((map, exercise) => {
        map[exercise.id] = exercise;
        return map;
    }, {});

    muscleGroups.forEach((group) => {
        counts[group.id] = {};

        dates.forEach((date) => {
            const dateLog = log[date] || {};

            let primaryCount = 0;
            let secondaryCount = 0;

            // Перебираємо всі вправи в логах для даної дати
            Object.keys(dateLog).forEach((exerciseId) => {
                const exercise = exerciseMap[exerciseId];
                if (exercise) {
                    const primaryExercises = exercise.muscleGroups.filter(
                        (mg) => mg.group === group.id && mg.priority === "primary"
                    );
                    if (primaryExercises.length > 0) {
                        primaryCount += dateLog[exerciseId].length;
                    }

                    const secondaryExercises = exercise.muscleGroups.filter(
                        (mg) => mg.group === group.id && mg.priority === "secondary"
                    );
                    if (secondaryExercises.length > 0) {
                        secondaryCount += dateLog[exerciseId].length;
                    }
                }
            });

            counts[group.id][date] = { primary: primaryCount, secondary: secondaryCount };
        });
    });

    return counts;
}

// src/pages/LogExercise.jsx
import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { getSetsByExercise, updateSetsByExercise } from "../services/exerciseService";
import "./LogExercise.css";

export default function LogExercise() {
    const { exerciseId } = useParams();
    const navigate = useNavigate();
    const today = new Date().toISOString().split("T")[0];

    const [sets, setSets] = useState(() => {
        const initialSets = getSetsByExercise(today, exerciseId);
        return initialSets.length > 0
            ? [...initialSets, { weight: "", reps: "" }]
            : [{ weight: "", reps: "" }];
    });

    const [pastLogs, setPastLogs] = useState([]);

    useEffect(() => {
        setSets(() => {
            const initialSets = getSetsByExercise(today, exerciseId);
            return initialSets.length > 0
                ? [...initialSets, { weight: "", reps: "" }]
                : [{ weight: "", reps: "" }];
        });

        fetchPastLogs();
    }, [exerciseId]);

    const fetchPastLogs = () => {
        const maxLogs = 10;
        const pastLogsCollected = [];
        const todayDate = new Date(today);
        const daysToFetch = 30; // Перевіряємо останні 30 днів

        for (let i = 1; i <= daysToFetch && pastLogsCollected.length < maxLogs; i++) {
            const pastDate = new Date(todayDate);
            pastDate.setDate(todayDate.getDate() - i);
            const formattedDate = pastDate.toISOString().split("T")[0];
            const setsForDate = getSetsByExercise(formattedDate, exerciseId);

            setsForDate.forEach((set) => {
                if (pastLogsCollected.length < maxLogs) {
                    pastLogsCollected.push({
                        date: formattedDate,
                        weight: set.weight,
                        reps: set.reps,
                    });
                }
            });
        }

        setPastLogs(pastLogsCollected);
    };

    const handleChange = (e, index) => {
        const { name, value } = e.target;

        const newSets = [...sets];
        newSets[index] = { ...newSets[index], [name]: value };

        const isLastSet = index === newSets.length - 1;
        const isSetComplete =
            parseFloat(newSets[index].weight) > 0 &&
            parseInt(newSets[index].reps, 10) > 0;

        if (isLastSet && isSetComplete) {
            setSets([...newSets, { weight: "", reps: "" }]);
        } else {
            setSets(newSets);
        }
    };

    const handleDelete = (index) => {
        const newSets = sets.filter((_, i) => i !== index);

        if (newSets.length === 0 || newSets.every((set) => set.weight && set.reps)) {
            newSets.push({ weight: "", reps: "" });
        }

        setSets(newSets);
    };

    const saveSets = () => {
        const completeSets = sets.filter(
            (set) => parseFloat(set.weight) > 0 && parseInt(set.reps, 10) > 0
        );

        updateSetsByExercise(today, exerciseId, completeSets);
        navigate("/");
    };

    // Функція для отримання формату назви вправи
    const formatExerciseName = (id) => {
        return id.replace(/_/g, " ");
    };

    return (
        <div className="container">
            <h1 className="heading">Log Exercise: {formatExerciseName(exerciseId)}</h1>
            <Link to="/" className="backLink">← Back to Muscle Group List</Link>

            <div className="formContainer">
                {sets.length === 0 ? (
                    <p>No sets logged.</p>
                ) : (
                    <table className="setsTable">
                        <thead>
                        <tr>
                            <th>Set</th>
                            <th>Weight (kg)</th>
                            <th>Reps</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {sets.map((setItem, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                    <input
                                        type="number"
                                        name="weight"
                                        placeholder="Weight (kg)"
                                        value={setItem.weight}
                                        onChange={(e) => handleChange(e, index)}
                                        className="input"
                                        min="0"
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        name="reps"
                                        placeholder="Reps"
                                        value={setItem.reps}
                                        onChange={(e) => handleChange(e, index)}
                                        className="input"
                                        min="0"
                                    />
                                </td>
                                <td>
                                    {!(sets.length === 1 && index === sets.length - 1 && !setItem.weight && !setItem.reps) && (
                                        <button
                                            className="deleteButton"
                                            onClick={() => handleDelete(index)}
                                        >
                                            Delete
                                        </button>
                                    )}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                )}
                <button onClick={saveSets} className="saveButton">
                    Save
                </button>
            </div>

            {/* Додана таблиця з останніми 10 виконаннями */}
            {pastLogs.length > 0 && (
                <div className="pastLogsContainer">
                    <h2 className="sectionHeading">Last {pastLogs.length} Sessions</h2>
                    <table className="pastLogsTable">
                        <thead>
                        <tr>
                            <th>Date</th>
                            <th>Weight (kg)</th>
                            <th>Reps</th>
                        </tr>
                        </thead>
                        <tbody>
                        {pastLogs.map((log, index) => (
                            <tr key={index}>
                                <td>{log.date}</td>
                                <td>{log.weight}</td>
                                <td>{log.reps}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
}

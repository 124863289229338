import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
//import { getMuscleGroupCountsAndExercises, getExerciseLog } from "../services/homeService";
import { muscleGroups } from "../data/muscleGroups";
import { exercises as allExercises } from "../data/exercises";
import "./Home.css";
import {
    getMuscleGroupCountsAndExercises
} from "../services/homeService";
import { getExerciseLog } from "../utils/localStorageUtils"; // ✅ Make sure this is here!
function Home() {
    const [dates, setDates] = useState([]);
    const [muscleGroupCounts, setMuscleGroupCounts] = useState({});
    const [loggedExercises, setLoggedExercises] = useState([]);
    const [exerciseLog, setExerciseLog] = useState({});

    useEffect(() => {
        const { counts, todayExercises, lastDates } = getMuscleGroupCountsAndExercises();
        const log = getExerciseLog(); // Get full exercise log
        console.log("📌 Retrieved workout data:", { counts, todayExercises, lastDates, log });

        setDates(lastDates);
        setMuscleGroupCounts(counts);
        setLoggedExercises(todayExercises);
        setExerciseLog(log); // Save the full log for later use
    }, []);

    const exerciseMap = Object.fromEntries(allExercises.map((ex) => [ex.id, ex]));

    const getCellStyleByCount = (count) => {
        if (count === 0) return {};
        if (count >= 1 && count <= 2) {
            return { backgroundColor: "#FFA500", color: "#fff" };
        }
        if (count >= 3) {
            return { backgroundColor: "#28a745", color: "#fff" };
        }
        return {};
    };

    const renderMuscleGroupHeaders = () => (
        <tr>
            <th>Muscle Group</th>
            {dates.map((date) => (
                <th key={date} className="dateHeader">{date}</th>
            ))}
        </tr>
    );

    const renderMuscleGroupRows = () =>
        muscleGroups.map((group) => {
            return (
                <tr key={group.id}>
                    <td className="muscleGroupCell">
                        <span className="muscleGroupText">{group.name}</span>
                        <Link to={`/SelectExercise/${group.id}`} className="addExerciseButton">+</Link>
                    </td>
                    {dates.map((date) => {
                        const groupCounts = muscleGroupCounts[group.id]?.[date] || { primary: 0, secondary: 0 };
                        const primaryCount = groupCounts.primary || 0;
                        const secondaryCount = groupCounts.secondary || 0;
                        const totalCount = primaryCount + secondaryCount;

                        console.log(`📌 Data for ${group.name} on ${date}:`, groupCounts);

                        // Retrieve exercises for the muscle group on this date from exerciseLog
                        const exercisesOnDate = Object.keys(exerciseLog[date] || {})
                            .map((exerciseId) => ({
                                id: exerciseId,
                                name: exerciseMap[exerciseId]?.name || `Unknown (${exerciseId})`,
                                sets: (exerciseLog[date][exerciseId] || []).length,
                                priority: allExercises.find((ex) => ex.id === exerciseId)?.muscleGroups.find((mg) => mg.group === group.id)?.priority,
                            }))
                            .filter((ex) => ex.sets > 0 && ex.priority); // Ensure exercises belong to this muscle group

                        console.log(`📌 Exercises on ${date} for ${group.name}:`, exercisesOnDate);

                        const directExercises = exercisesOnDate
                            .filter((ex) => ex.priority === "primary")
                            .map((ex) => (
                                <React.Fragment key={ex.id}>
                                    <Link to={`/LogExercise/${ex.id}`} className="exerciseLink">{ex.name}</Link> ({ex.sets})
                                    <br />
                                </React.Fragment>
                            ));

                        const indirectExercises = exercisesOnDate
                            .filter((ex) => ex.priority === "secondary")
                            .map((ex) => (
                                <React.Fragment key={ex.id}>
                                    <Link to={`/LogExercise/${ex.id}`} className="exerciseLink">{ex.name}</Link> ({ex.sets})
                                    <br />
                                </React.Fragment>
                            ));

                        return (
                            <td key={`${group.id}-${date}`} style={getCellStyleByCount(totalCount)}>
                                <div>
                                    {directExercises.length > 0 && (
                                        <>
                                            <strong>Direct:</strong> {directExercises}
                                        </>
                                    )}
                                    {indirectExercises.length > 0 && (
                                        <>
                                            <strong>Indirect:</strong> {indirectExercises}
                                        </>
                                    )}
                                </div>
                            </td>
                        );
                    })}
                </tr>
            );
        });

    return (
        <div className="container">
            <h1 className="heading">Free Simple Home Weight Training Workout Tracker</h1>
            <img src="images/fit-log-logo.png" alt="Fit-Log Logo" className="logo"/>

            <div className="section">
                <div className="logExerciseContainer">
                    <h2 className="sectionHeading">Workout Tracker</h2>
                    Log Exercise:
                    <Link to="/SelectExercise" className="addExerciseButton"> +</Link>
                </div>
            </div>

            <div className="section">
                <h2 className="sectionHeading">Today's Logged Exercises</h2>
                {loggedExercises.length === 0 ? (
                    <p>You haven't logged any exercises today.<br/>
                        Select a muscle group in the "Workout Tracker" to choose an exercise and log sets.</p>
                ) : (
                    <table className="compactExerciseTable">
                        <thead>
                        <tr>
                            <th>Exercise</th>
                            <th>Number of Sets</th>
                            <th>Weight (kg)</th>
                        </tr>
                        </thead>
                        <tbody>
                        {loggedExercises.map(({ exerciseId, sets }) => {
                            const exercise = exerciseMap[exerciseId];
                            const exerciseName = exercise ? exercise.name : `Unknown (${exerciseId})`;
                            const weightRange = sets.length > 0
                                ? `${Math.min(...sets.map(s => s.weight))}-${Math.max(...sets.map(s => s.weight))}`
                                : "—";

                            return (
                                <tr key={exerciseId}>
                                    <td>
                                        <Link to={`/LogExercise/${exerciseId}`} className="linkButton">
                                            {exerciseName}
                                        </Link>
                                    </td>
                                    <td>{sets.length}</td>
                                    <td>{weightRange}</td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                )}
            </div>

            <div className="section">
                <h2 className="sectionHeading">Workout Progress</h2>
                {dates.length === 0 ? (
                    <p>No data available.</p>
                ) : (
                    <div className="tableContainer">
                        <table className="muscleTable" aria-label="Workout Tracker">
                            <thead>{renderMuscleGroupHeaders()}</thead>
                            <tbody>{renderMuscleGroupRows()}</tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Home;

import { getExerciseSets, saveExerciseSets } from "../utils/localStorageUtils";
import { exercises } from "../data/exercises";
import { muscleGroups } from "../data/muscleGroups";
import { getAllExerciseSets, saveAllExerciseSets } from "../utils/localStorageUtils";
import { getExerciseLog, saveExerciseLog } from "../utils/localStorageUtils";

/**
 * Отримує підходи для вправи на конкретну дату.
 * @param {string} date Дата у форматі YYYY-MM-DD.
 * @param {string} exerciseId Ідентифікатор вправи.
 * @returns {Array} Підходи для вправи.
 */
export function getSetsByExercise(date, exerciseId) {
    const log = getExerciseLog();
    const todayLog = log[date] || {};
    return todayLog[exerciseId] || [];
}

/**
 * Оновлює підходи для вправи на конкретну дату.
 * @param {string} date Дата у форматі YYYY-MM-DD.
 * @param {string} exerciseId Ідентифікатор вправи.
 * @param {Array} sets Масив підходів.
 */
export function updateSetsByExercise(date, exerciseId, sets) {
    const log = getExerciseLog();
    const todayLog = log[date] || {};

    const completeSets = sets.filter((set) => set.weight && set.reps);

    if (completeSets.length > 0) {
        todayLog[exerciseId] = completeSets; // Зберігаємо тільки заповнені підходи
    } else {
        delete todayLog[exerciseId]; // Видаляємо вправу, якщо немає заповнених підходів
    }

    log[date] = todayLog;
    saveExerciseLog(log);
}

/**
 * Перевіряє, чи є хоч один заповнений підхід.
 * @param {Array} sets Масив підходів.
 * @returns {boolean} True, якщо є заповнені підходи.
 */
export function hasCompleteSets(sets) {
    return sets.some((set) => set.weight && set.reps);
}

/**
 * Ініціалізує список підходів для вправи.
 * @param {string} exerciseId ID вправи.
 * @returns {Array} Список підходів.
 */
export function initializeExerciseSets(exerciseId) {
    const savedSets = getExerciseSets(exerciseId);

    // Додаємо порожній підхід, якщо останній заповнений
    if (savedSets.length > 0 && savedSets[savedSets.length - 1].weight && savedSets[savedSets.length - 1].reps) {
        return [...savedSets, { weight: "", reps: "" }];
    }

    return savedSets.length > 0 ? savedSets : [{ weight: "", reps: "" }];
}

/**
 * Оновлює список підходів та зберігає їх у localStorage.
 * @param {string} exerciseId ID вправи.
 * @param {Array} sets Список підходів.
 */
export function updateExerciseSets(exerciseId, sets) {
    const completeSets = sets.filter((set) => set.weight && set.reps);
    saveExerciseSets(exerciseId, completeSets);
}

/**
 * Перевіряє, чи останній підхід заповнений.
 * @param {Array} sets Список підходів.
 * @param {number} index Індекс підходу.
 * @returns {boolean} Чи заповнений підхід.
 */
export function isLastSetComplete(sets, index) {
    const isLastSet = index === sets.length - 1;
    const isSetComplete = sets[index].weight && sets[index].reps;
    return isLastSet && isSetComplete;
}

/**
 * Повертає об'єкт м'язової групи за її ID.
 * @param {string} muscleGroupId ID м'язової групи.
 * @returns {Object|null} Об'єкт м'язової групи або null.
 */
export function getMuscleGroupById(muscleGroupId) {
    return muscleGroups.find((mg) => mg.id === muscleGroupId) || null;
}

/**
 * Фільтрує вправи за пріоритетом (primary/secondary) і м'язовою групою.
 * @param {string} muscleGroupId ID м'язової групи.
 * @param {string} priority Пріоритет ("primary" або "secondary").
 * @returns {Array} Масив вправ.
 */
export function getExercisesByMuscleGroup(muscleGroupId, priority) {
    return exercises.filter((exercise) =>
        exercise.muscleGroups?.some(
            (mg) =>
                mg.group &&
                typeof mg.group === "string" &&
                mg.group.toLowerCase() === muscleGroupId.toLowerCase() &&
                mg.priority === priority
        )
    );
}

/**
 * Отримує список вправ із записаними підходами з localStorage.
 * @returns {Array} Список вправ.
 */
export function getLoggedExercises() {
    const allExercisesSets = getAllExerciseSets();
    return Object.keys(allExercisesSets)
        .map((exerciseId) => ({
            exerciseId,
            sets: allExercisesSets[exerciseId],
        }))
        .filter((exercise) => exercise.sets.length > 0);
}

/**
 * Видаляє вправу за її ID.
 * @param {string} exerciseId ID вправи.
 * @param {Array} loggedExercises Список вправ.
 * @returns {Array} Оновлений список вправ.
 */
export function deleteExercise(exerciseId, loggedExercises) {
    const updatedExercises = loggedExercises.filter((ex) => ex.exerciseId !== exerciseId);

    const allExercisesSets = {};
    updatedExercises.forEach((ex) => {
        allExercisesSets[ex.exerciseId] = ex.sets;
    });

    saveAllExerciseSets(allExercisesSets);
    return updatedExercises;
}

export function getAllExercises() {
    return exercises; // Assuming "exercises" is an array of all exercises
}

/**
 * Обчислює кількість primary і secondary вправ для кожної м'язової групи.
 * @param {Array} loggedExercises Список записаних вправ.
 * @returns {Object} Об'єкт з підрахунком вправ.
 */
export function calculateMuscleGroupCounts(loggedExercises) {
    const counts = {};
    muscleGroups.forEach((group) => {
        counts[group.id] = {
            primary: 0,
            secondary: 0,
        };
    });

    loggedExercises.forEach(({ exerciseId }) => {
        const exercise = exercises.find((ex) => ex.id === exerciseId);
        if (exercise) {
            exercise.muscleGroups.forEach((mg) => {
                const groupId = mg.group;
                if (counts[groupId]) {
                    if (mg.priority === "primary") {
                        counts[groupId].primary += 1;
                    } else if (mg.priority === "secondary") {
                        counts[groupId].secondary += 1;
                    }
                }
            });
        }
    });

    return counts;
}

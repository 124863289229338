import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import SelectExercise from "./pages/SelectExercise";
import LogExercise from "./pages/LogExercise";

function App() {
  return (
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
            <Route path="/SelectExercise" element={<SelectExercise />} />
            <Route path="/SelectExercise/:muscleGroup" element={<SelectExercise />} />
          <Route path="/LogExercise/:exerciseId" element={<LogExercise />} />
        </Routes>
      </Router>
  );
}

export default App;
import { getLastNDates, calculateMuscleGroupCountsByDate } from "./muscleGroupService";
import { getExerciseLog, saveExerciseLog } from "../utils/localStorageUtils";
import { muscleGroups } from "../data/muscleGroups";

/**
 * Retrieves workout data for the last active workout days (max 7 + today).
 * Returns selected dates, muscle group counts, and today's exercises.
 */
export const getMuscleGroupCountsAndExercises = () => {
    const log = getExerciseLog(); // ✅ Make sure this function is properly imported

    // Get all dates with recorded exercises
    const activeDates = Object.keys(log)
        .filter(date => Object.keys(log[date]).length > 0) // Only include days with workouts
        .sort((a, b) => new Date(b) - new Date(a)); // Sort from newest to oldest

    console.log("Active workout dates:", activeDates);

    // Get today's date
    const today = new Date().toISOString().split("T")[0];

    // Always include today, even if it's empty
    if (!activeDates.includes(today)) {
        activeDates.unshift(today);
    }

    // Select last 7 active days + today
    const lastDates = activeDates.slice(0, 8); // Max 7 + today

    console.log("Final selected dates for table:", lastDates);

    // Initialize exercise counts for each muscle group
    const initialCounts = {};
    muscleGroups.forEach((group) => {
        initialCounts[group.id] = lastDates.reduce((acc, date) => {
            acc[date] = { primary: 0, secondary: 0 }; // Default: 0 sets
            return acc;
        }, {});
    });

    // Compute exercise counts for each muscle group by date
    const counts = calculateMuscleGroupCountsByDate(lastDates, log, initialCounts);

    // Get today's exercises
    const todayLog = log[today] || {};
    const todayExercises = Object.keys(todayLog).map((exerciseId) => ({
        exerciseId,
        sets: todayLog[exerciseId],
    }));

    return { lastDates, counts, todayExercises };
};

/**
 * Initializes today's exercise log (deletes an exercise by ID).
 * @param {Array} dates - List of dates.
 * @param {string} exerciseId - ID of the exercise to delete.
 */
export const initializeExerciseLog = (dates, exerciseId) => {
    const log = getExerciseLog();
    const today = new Date().toISOString().split("T")[0];
    const todayLog = log[today] || {};

    // Remove the exercise from today's log
    delete todayLog[exerciseId];

    log[today] = todayLog;
    saveExerciseLog(log);
};

export const exercises = [
    {
        id: "bench_press",
        name: "Bench Press",
        muscleGroups: [
                { group: "chest", priority: "primary" },
                { group: "triceps", priority: "secondary" },
                { group: "deltoids", priority: "secondary" }
        ],
    },
    {
        id: "incline_bench_press",
        name: "Incline Bench Press",
        muscleGroups: [
                { group: "chest", priority: "primary" },
                { group: "deltoids", priority: "secondary" },
                { group: "triceps", priority: "secondary" }
        ],
    },
    {
        id: "decline_bench_press",
        name: "Decline Bench Press",
        muscleGroups: [
                { group: "chest", priority: "primary" },
                { group: "triceps", priority: "secondary" },
                { group: "deltoids", priority: "secondary" }
        ],
    },
    {
        id: "chest_fly",
        name: "Chest Fly",
        muscleGroups: [
                { group: "chest", priority: "primary" },
                { group: "deltoids", priority: "secondary" }
        ],
    },
    {
        id: "push_up",
        name: "Push-Up",
        muscleGroups: [
                { group: "chest", priority: "primary" },
                { group: "triceps", priority: "secondary" },
                { group: "deltoids", priority: "secondary" }
        ],
    },
    {
        id: "pull_up",
        name: "Pull-Up",
        muscleGroups: [
                { group: "back", priority: "primary" },
                { group: "biceps", priority: "secondary" },
                { group: "forearms", priority: "secondary" }
        ],
    },
    {
        id: "chin_up",
        name: "Chin-Up",
        muscleGroups: [
                { group: "back", priority: "primary" },
                { group: "biceps", priority: "secondary" },
                { group: "forearms", priority: "secondary" }
        ],
    },
    {
        id: "lat_pulldown",
        name: "Lat Pulldown",
        muscleGroups: [
                { group: "back", priority: "primary" },
                { group: "biceps", priority: "secondary" }
        ],
    },
    {
        id: "bent_over_row",
        name: "Bent Over Row",
        muscleGroups: [
                { group: "back", priority: "primary" },
                { group: "biceps", priority: "secondary" },
                { group: "forearms", priority: "secondary" }
        ],
    },
    {
        id: "single_arm_row",
        name: "Single-Arm Row",
        muscleGroups: [
                { group: "back", priority: "primary" },
                { group: "biceps", priority: "secondary" }
        ],
    },
    {
        id: "t_bar_row",
        name: "T-Bar Row",
        muscleGroups: [
                { group: "back", priority: "primary" },
                { group: "biceps", priority: "secondary" }
        ],
    },
    {
        id: "deadlift",
        name: "Deadlift",
        muscleGroups: [
                { group: "back", priority: "primary" },
                { group: "legs", priority: "secondary" }
        ],
    },
    {
        id: "squat",
        name: "Squat",
        muscleGroups: [
                { group: "legs", priority: "primary" },
                { group: "abs", priority: "secondary" }
        ],
    },
    {
        id: "front_squat",
        name: "Front Squat",
        muscleGroups: [
                { group: "legs", priority: "primary" },
                { group: "abs", priority: "secondary" },
                { group: "deltoids", priority: "secondary" }
        ],
    },
    {
        id: "leg_press",
        name: "Leg Press",
        muscleGroups: [
                { group: "legs", priority: "primary" },
                { group: "glutes", priority: "secondary" }
        ],
    },
    {
        id: "lunge",
        name: "Lunge",
        muscleGroups: [
                { group: "legs", priority: "primary" },
                { group: "glutes", priority: "secondary" },
                { group: "abs", priority: "secondary" }
        ],
    },
    {
        id: "leg_curl",
        name: "Leg Curl",
        muscleGroups: [
                { group: "legs", priority: "primary" },
                { group: "hamstrings", priority: "secondary" }
        ],
    },
    {
        id: "leg_extension",
        name: "Leg Extension",
        muscleGroups: [
                { group: "legs", priority: "primary" },
                { group: "quadriceps", priority: "secondary" }
        ],
    },
    {
        id: "calf_raise",
        name: "Calf Raise",
        muscleGroups: [
                { group: "legs", priority: "primary" },
                { group: "calves", priority: "secondary" }
        ],
    },
    {
        id: "overhead_press",
        name: "Overhead Press",
        muscleGroups: [
                { group: "deltoids", priority: "primary" },
                { group: "triceps", priority: "secondary" }
        ],
    },
    {
        id: "lateral_raise",
        name: "Lateral Raise",
        muscleGroups: [
                { group: "deltoids", priority: "primary" },
                { group: "trapezius", priority: "secondary" }
        ],
    },
    {
        id: "front_raise",
        name: "Front Raise",
        muscleGroups: [
                { group: "deltoids", priority: "primary" },
                { group: "trapezius", priority: "secondary" }
        ],
    },
    {
        id: "rear_delt_fly",
        name: "Rear Delt Fly",
        muscleGroups: [
                { group: "deltoids", priority: "primary" },
                { group: "back", priority: "secondary" }
        ],
    },
    {
        id: "arnold_press",
        name: "Arnold Press",
        muscleGroups: [
                { group: "deltoids", priority: "primary" },
                { group: "triceps", priority: "secondary" }
        ],
    },
    {
        id: "bicep_curl",
        name: "Bicep Curl",
        muscleGroups: [
                { group: "biceps", priority: "primary" },
                { group: "forearms", priority: "secondary" }
        ],
    },
    {
        id: "hammer_curl",
        name: "Hammer Curl",
        muscleGroups: [
                { group: "biceps", priority: "primary" },
                { group: "forearms", priority: "secondary" }
        ],
    },
    {
        id: "preacher_curl",
        name: "Preacher Curl",
        muscleGroups: [
                { group: "biceps", priority: "primary" }
        ],
    },
    {
        id: "concentration_curl",
        name: "Concentration Curl",
        muscleGroups: [
                { group: "biceps", priority: "primary" }
        ],
    },
    {
        id: "tricep_pushdown",
        name: "Tricep Pushdown",
        muscleGroups: [
                { group: "triceps", priority: "primary" },
                { group: "deltoids", priority: "secondary" }
        ],
    },
    {
        id: "tricep_extension",
        name: "Tricep Extension",
        muscleGroups: [
                { group: "triceps", priority: "primary" }
        ],
    },
    {
        id: "dips",
        name: "Dips",
        muscleGroups: [
                { group: "triceps", priority: "primary" },
                { group: "chest", priority: "secondary" },
                { group: "deltoids", priority: "secondary" }
        ],
    },
    {
        id: "skullcrusher",
        name: "Skullcrusher",
        muscleGroups: [
                { group: "triceps", priority: "primary" }
        ],
    },
    {
        id: "close_grip_bench_press",
        name: "Close Grip Bench Press",
        muscleGroups: [
                { group: "triceps", priority: "primary" },
                { group: "chest", priority: "secondary" }
        ],
    },
    {
        id: "crunch",
        name: "Crunch",
        muscleGroups: [
                { group: "abs", priority: "primary" }
        ],
    },
    {
        id: "plank",
        name: "Plank",
        muscleGroups: [
                { group: "abs", priority: "primary" },
                { group: "back", priority: "secondary" }
        ],
    },
    {
        id: "russian_twist",
        name: "Russian Twist",
        muscleGroups: [
                { group: "abs", priority: "primary" }
        ],
    },
    {
        id: "leg_raise",
        name: "Leg Raise",
        muscleGroups: [
                { group: "abs", priority: "primary" },
                { group: "hip_flexors", priority: "secondary" }
        ],
    },
    {
        id: "bicycle_crunch",
        name: "Bicycle Crunch",
        muscleGroups: [
                { group: "abs", priority: "primary" },
                { group: "obliques", priority: "secondary" }
        ],
    },
    {
        id: "wrist_curl",
        name: "Wrist Curl",
        muscleGroups: [
                { group: "forearms", priority: "primary" }
        ],
    },
    {
        id: "reverse_curl",
        name: "Reverse Curl",
        muscleGroups: [
                { group: "forearms", priority: "primary" },
                { group: "biceps", priority: "secondary" }
        ],
    },
    {
        id: "farmer_walk",
        name: "Farmer's Walk",
        muscleGroups: [
                { group: "forearms", priority: "primary" },
                { group: "traps", priority: "secondary" },
                { group: "legs", priority: "secondary" }
        ],
    },
    {
        id: "shrug",
        name: "Shrug",
        muscleGroups: [
                { group: "trapezius", priority: "primary" },
                { group: "deltoids", priority: "secondary" }
        ],
    },
    {
        id: "face_pull",
        name: "Face Pull",
        muscleGroups: [
                { group: "rear_deltoids", priority: "primary" },
                { group: "upper_back", priority: "secondary" }
        ],
    },
    {
        id: "cable_row",
        name: "Cable Row",
        muscleGroups: [
                { group: "back", priority: "primary" },
                { group: "biceps", priority: "secondary" }
        ],
    },
    {
        id: "barbell_curl",
        name: "Barbell Curl",
        muscleGroups: [
                { group: "biceps", priority: "primary" },
                { group: "forearms", priority: "secondary" }
        ],
    },
    {
        id: "cable_crossover",
        name: "Cable Crossover",
        muscleGroups: [
                { group: "chest", priority: "primary" },
                { group: "deltoids", priority: "secondary" }
        ],
    },
    {
        id: "dumbbell_press",
        name: "Dumbbell Press",
        muscleGroups: [
                { group: "chest", priority: "primary" },
                { group: "triceps", priority: "secondary" }
        ],
    },
    {
        id: "push_press",
        name: "Push Press",
        muscleGroups: [
                { group: "deltoids", priority: "primary" },
                { group: "triceps", priority: "secondary" },
                { group: "legs", priority: "secondary" }
        ],
    },
    {
        id: "romanian_deadlift",
        name: "Romanian Deadlift",
        muscleGroups: [
                { group: "hamstrings", priority: "primary" },
                { group: "back", priority: "secondary" }
        ],
    },
    {
        id: "good_morning",
        name: "Good Morning",
        muscleGroups: [
                { group: "hamstrings", priority: "primary" },
                { group: "lower_back", priority: "secondary" }
        ],
    },
    {
        id: "glute_bridge",
        name: "Glute Bridge",
        muscleGroups: [
                { group: "glutes", priority: "primary" },
                { group: "hamstrings", priority: "secondary" }
        ],
    },
    {
        id: "hip_thrust",
        name: "Hip Thrust",
        muscleGroups: [
                { group: "glutes", priority: "primary" },
                { group: "hamstrings", priority: "secondary" }
        ],
    },
    {
        id: "standing_calf_raise",
        name: "Standing Calf Raise",
        muscleGroups: [
                { group: "calves", priority: "primary" }
        ],
    },
    {
        id: "seated_calf_raise",
        name: "Seated Calf Raise",
        muscleGroups: [
                { group: "calves", priority: "primary" }
        ],
    },
    {
        id: "battle_rope",
        name: "Battle Rope",
        muscleGroups: [
                { group: "forearms", priority: "primary" },
                { group: "shoulders", priority: "secondary" }
        ],
    },
    {
        id: "medicine_ball_throw",
        name: "Medicine Ball Throw",
        muscleGroups: [
                { group: "chest", priority: "primary" },
                { group: "triceps", priority: "secondary" }
        ],
    },
    {
        id: "pull_over",
        name: "Pull Over",
        muscleGroups: [
                { group: "chest", priority: "primary" },
                { group: "back", priority: "secondary" }
        ],
    },
    {
        id: "cable_fly",
        name: "Cable Fly",
        muscleGroups: [
                { group: "chest", priority: "primary" },
                { group: "deltoids", priority: "secondary" }
        ],
    },
    {
        id: "incline_dumbbell_press",
        name: "Incline Dumbbell Press",
        muscleGroups: [
                { group: "chest", priority: "primary" },
                { group: "deltoids", priority: "secondary" },
                { group: "triceps", priority: "secondary" }
        ],
    },
    {
        id: "decline_dumbbell_press",
        name: "Decline Dumbbell Press",
        muscleGroups: [
                { group: "chest", priority: "primary" },
                { group: "triceps", priority: "secondary" }
        ],
    },
    {
        id: "dumbbell_fly",
        name: "Dumbbell Fly",
        muscleGroups: [
                { group: "chest", priority: "primary" },
                { group: "deltoids", priority: "secondary" }
        ],
    },
    {
        id: "cable_pullthrough",
        name: "Cable Pullthrough",
        muscleGroups: [
                { group: "hamstrings", priority: "primary" },
                { group: "glutes", priority: "secondary" }
        ],
    },
    {
        id: "reverse_lunge",
        name: "Reverse Lunge",
        muscleGroups: [
                { group: "legs", priority: "primary" },
                { group: "glutes", priority: "secondary" }
        ],
    },
    {
        id: "sumo_squat",
        name: "Sumo Squat",
        muscleGroups: [
                { group: "legs", priority: "primary" },
                { group: "glutes", priority: "secondary" }
        ],
    },
    {
        id: "step_up",
        name: "Step-Up",
        muscleGroups: [
                { group: "legs", priority: "primary" },
                { group: "glutes", priority: "secondary" }
        ],
    },
    {
        id: "box_jump",
        name: "Box Jump",
        muscleGroups: [
                { group: "legs", priority: "primary" },
                { group: "calves", priority: "secondary" }
        ],
    },
    {
        id: "pistol_squat",
        name: "Pistol Squat",
        muscleGroups: [
                { group: "legs", priority: "primary" },
                { group: "glutes", priority: "secondary" }
        ],
    },
    {
        id: "reverse_cable_curl",
        name: "Reverse Cable Curl",
        muscleGroups: [
                { group: "forearms", priority: "primary" },
                { group: "biceps", priority: "secondary" }
        ],
    },
    {
        id: "wrist_roll",
        name: "Wrist Roll",
        muscleGroups: [
                { group: "forearms", priority: "primary" }
        ],
    },
    {
        id: "rotational_curl",
        name: "Rotational Curl",
        muscleGroups: [
                { group: "forearms", priority: "primary" },
                { group: "biceps", priority: "secondary" }
        ],
    },
    {
        id: "incline_bicep_curl",
        name: "Incline Bicep Curl",
        muscleGroups: [
                { group: "biceps", priority: "primary" },
                { group: "forearms", priority: "secondary" }
        ],
    },
    {
        id: "cable_tricep_extension",
        name: "Cable Tricep Extension",
        muscleGroups: [
                { group: "triceps", priority: "primary" },
                { group: "deltoids", priority: "secondary" }
        ],
    },
    {
        id: "cable_bicep_curl",
        name: "Cable Bicep Curl",
        muscleGroups: [
                { group: "biceps", priority: "primary" },
                { group: "forearms", priority: "secondary" }
        ],
    },
    {
        id: "cable_lateral_raise",
        name: "Cable Lateral Raise",
        muscleGroups: [
                { group: "deltoids", priority: "primary" },
                { group: "trapezius", priority: "secondary" }
        ],
    },
    {
        id: "cable_front_raise",
        name: "Cable Front Raise",
        muscleGroups: [
                { group: "deltoids", priority: "primary" },
                { group: "trapezius", priority: "secondary" }
        ],
    },
    {
        id: "machine_row",
        name: "Machine Row",
        muscleGroups: [
                { group: "back", priority: "primary" },
                { group: "biceps", priority: "secondary" }
        ],
    },
    {
        id: "machine_press",
        name: "Machine Press",
        muscleGroups: [
                { group: "chest", priority: "primary" },
                { group: "triceps", priority: "secondary" }
        ],
    },
    {
        id: "machine_curl",
        name: "Machine Curl",
        muscleGroups: [
                { group: "biceps", priority: "primary" },
                { group: "forearms", priority: "secondary" }
        ],
    },
    {
        id: "machine_tricep_pushdown",
        name: "Machine Tricep Pushdown",
        muscleGroups: [
                { group: "triceps", priority: "primary" },
                { group: "deltoids", priority: "secondary" }
        ],
    },
    {
        id: "machine_leg_extension",
        name: "Machine Leg Extension",
        muscleGroups: [
                { group: "legs", priority: "primary" },
                { group: "quadriceps", priority: "secondary" }
        ],
    },
    {
        id: "machine_leg_curl",
        name: "Machine Leg Curl",
        muscleGroups: [
                { group: "legs", priority: "primary" },
                { group: "hamstrings", priority: "secondary" }
        ],
    },
    {
        id: "machine_calf_raise",
        name: "Machine Calf Raise",
        muscleGroups: [
                { group: "calves", priority: "primary" }
        ],
    },
    {
        id: "cable_face_pull",
        name: "Cable Face Pull",
        muscleGroups: [
                { group: "rear_deltoids", priority: "primary" },
                { group: "upper_back", priority: "secondary" }
        ],
    },
    {
        id: "cable_reverse_fly",
        name: "Cable Reverse Fly",
        muscleGroups: [
                { group: "rear_deltoids", priority: "primary" },
                { group: "upper_back", priority: "secondary" }
        ],
    },
    {
        id: "cable_high_pull",
        name: "Cable High Pull",
        muscleGroups: [
                { group: "deltoids", priority: "primary" },
                { group: "trapezius", priority: "secondary" }
        ],
    },
    {
        id: "cable_low_row",
        name: "Cable Low Row",
        muscleGroups: [
                { group: "back", priority: "primary" },
                { group: "biceps", priority: "secondary" }
        ],
    },
    {
        id: "kettlebell_deadlift",
        name: "Kettlebell Deadlift",
        muscleGroups: [
                { group: "legs", priority: "primary" },
                { group: "back", priority: "secondary" }
        ],
    },
    {
        id: "kettlebell_squat",
        name: "Kettlebell Squat",
        muscleGroups: [
                { group: "legs", priority: "primary" },
                { group: "glutes", priority: "secondary" }
        ],
    },
    {
        id: "kettlebell_swing",
        name: "Kettlebell Swing",
        muscleGroups: [
                { group: "hamstrings", priority: "primary" },
                { group: "glutes", priority: "secondary" },
                { group: "lower_back", priority: "secondary" }
        ],
    },
    {
        id: "medicine_ball_slam",
        name: "Medicine Ball Slam",
        muscleGroups: [
                { group: "abs", priority: "primary" },
                { group: "shoulders", priority: "secondary" }
        ],
    },
    {
        id: "medicine_ball_twist",
        name: "Medicine Ball Twist",
        muscleGroups: [
                { group: "abs", priority: "primary" },
                { group: "obliques", priority: "secondary" }
        ],
    },
    {
        id: "barbell_shrug",
        name: "Barbell Shrug",
        muscleGroups: [
                { group: "trapezius", priority: "primary" },
                { group: "deltoids", priority: "secondary" }
        ],
    },
    {
        id: "dumbbell_shrug",
        name: "Dumbbell Shrug",
        muscleGroups: [
                { group: "trapezius", priority: "primary" },
                { group: "deltoids", priority: "secondary" }
        ],
    }
];
